<template>
  <div class="container flex flex-direction-column fs14">
    <div class="container-header bgth flex-s0 flex flex-align-center">
      <div
        class="search-input-box relative flex-1 flex flex-align-center bgf bdr2 pr8 pl12"
      >
        <form class="flex-1" action="javascript:return true">
          <input
            ref="searchInput"
            v-model="searchText"
            placeholder="请输入你要找的内容"
            placeholder-class="cl9c"
            class="search-input"
            type="search"
            autofocus
            @focus="isFocus = true"
            @blur="onBlur"
            @keydown="onkeydown($event)"
          />
        </form>
        <div
          v-show="isFocus && searchText"
          class="icon-frame flex flex-align-center"
          @click="clearSearchValue"
        >
          <img class="icon" src="@/assets/img/common/clean.svg" />
        </div>
        <div class="icon-frame flex flex-align-center" @click="searchConfirm">
          <img class="icon" src="@/assets/img/common/search.svg" />
        </div>
      </div>
      <div
        class="cancel-btn flex-s0 clff pl8 pr15 flex flex-align-center"
        @click="onCancel"
      >
        取消
      </div>
    </div>
    <div class="flex-1 overflow-h">
      <div
        v-if="searchHistoryData.length"
        class="scroll-container overflow-y-scroll bgf5"
      >
        <div class="search-history pbs">
          <div
            v-for="(item, index) in searchHistoryData"
            :key="index"
            class="flex flex-justify-between flex-align-center cl29 pl15 pr12 ptb12"
            @click="searchConfirm(index)"
          >
            <div class="flex flex-align-center">
              <img
                class="icon mr8 flex-s0"
                src="@/assets/img/material-library/time.svg"
              />
              <span class="line-1">{{ item }}</span>
            </div>
            <div class="icon-box ml4" @click.stop="onRemove(index)">
              <img class="icon" src="@/assets/img/material-library/close.svg" />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="search-history-empty tc fs12 cl9c">搜索历史为空</div>
    </div>
  </div>
</template>

<script>
// import { getRouteParams, navigateBack } from '@/utils/router.js'
import bus from '@/utils/bus'

export default {
  data() {
    return {
      searchHistoryData: [], // 搜索记录
      isFocus: false, // 搜索框聚焦
      searchText: '' // 搜索框内容
    }
  },
  mounted() {
    const _this = this
    const { searchText = '' } = this.$route.query || {}
    this.searchText = searchText
    const searchHistoryData = localStorage.getItem('materialSearchHistory')
    this.searchHistoryData = searchHistoryData
      ? JSON.parse(searchHistoryData)
      : []
  },
  methods: {
    onBlur() {
      setTimeout(() => {
        this.isFocus = false
      }, 500)
    },
    // 清除搜索框文字
    clearSearchValue() {
      const _this = this
      this.searchText = ''
      setTimeout(() => {
        _this.$refs.searchInput.focus()
        // _this.isFocus = true
      }, 500)
    },
    onkeydown(event) {
      console.log('onkeydown', event)
      if (event.keyCode == 13) {
        this.searchConfirm()
      }
    },
    // 搜索提交
    searchConfirm(index) {
      console.log('搜索提交', this.searchText)
      const { searchHistoryData = [], searchText = '' } = this
      let data = []
      let text = ''
      if (
        Object.prototype.toString.call(index) === '[object Number]' ||
        index === 0
      ) {
        text = searchHistoryData[index]
        data = [
          searchHistoryData[index],
          ...searchHistoryData.slice(0, index),
          ...searchHistoryData.slice(index + 1, searchHistoryData.length)
        ]
      } else {
        text = searchText
        if (!searchText) {
          data = [...searchHistoryData]
        } else {
          const findIndex = searchHistoryData.findIndex(
            (item) => item === searchText
          )
          if (findIndex > -1) {
            data = [
              searchHistoryData[findIndex],
              ...searchHistoryData.slice(0, findIndex),
              ...searchHistoryData.slice(
                findIndex + 1,
                searchHistoryData.length
              )
            ]
          } else {
            data = [searchText, ...searchHistoryData]
          }
        }
      }
      localStorage.setItem(
        'materialSearchHistory',
        JSON.stringify(data.slice(0, 10))
      )
      bus.emit('onSearch', text)
      this.$router.back()
    },
    // 删除历史记录
    onRemove(index) {
      this.searchHistoryData.splice(index, 1)
      localStorage.setItem(
        'materialSearchHistory',
        JSON.stringify(this.searchHistoryData)
      )
    },
    // 取消搜索
    onCancel() {
      bus.emit('onSearch', '')
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
.icon {
  width: 16px;
  height: 16px;
}
.pl12 {
  padding-left: 12px;
}
.pr12 {
  padding-right: 12px;
}
input[type='search'] {
  -webkit-appearance: none;
}
input::-webkit-search-cancel-button {
  display: none;
}
.container {
  width: 100vw;
  height: 100vh;

  .container-header {
    padding: 6px 0;

    .search-input-box {
      height: 32px;
      margin-left: 15px;
      border: 1px solid #f2f3f5;

      .search-input {
        // position: relative;
        // z-index: 1;
        width: 100%;
        background-color: #fff;
        border: none;
        height: 32px;
        line-height: 32px;
        padding-top: 5px;
      }
      .icon-frame {
        // position: absolute;
        // top: 0;
        // z-index: 9999;
        height: 100%;
        padding: 0 4px;
      }
    }
    .cancel-btn {
      height: 100%;
    }
  }
  .scroll-container {
    width: 100%;
    height: 100%;
  }
  .search-history-empty {
    padding-top: 120px;
  }
}
</style>
